import { MenuItemV1Model } from "../models/menu-v1.model";

export const MenuReservas: MenuItemV1Model[] = [
  {
    reservas: true,
    isvertical: false,
    allowed: true,
    disabled: false,
    id: 1,   /** será montdo a númeração em tempo real de carregamento do banco de dados */
    label: 'Reservas',
    icon: 'bx bxs-dashboard',
    link: '/reservas',
  },
  {
    reservas: true,
    isvertical: false,
    allowed: undefined,
    disabled: false,
    id: 2,
    label: 'Cupons',
    icon: 'fas fa-ticket-alt',
    hsubitens: [
      {
        id: 3,
        label: 'Cupom Individual',
        link: '/reservas/cupom-individual',
        icon: 'fas fa-ticket-alt',
        parentId: 2,
        allowed: undefined,
        disabled: false
      },
      {
        id: 4,
        label: 'Cupons Promocionais',
        link: '/reservas/cupom-promocional',
        icon: 'fas fa-ticket-alt',
        parentId: 2,
        allowed: undefined,
        disabled: false
      },
      {
        id: 14,
        label: 'Cupons Temporada',
        link: '/reservas/cupom-temporada',
        icon: 'fas fa-ticket-alt',
        parentId: 2,
        allowed: undefined,
        disabled: false,
      }
    ]
  },
  {
    reservas: true,
    isvertical: false,
    allowed: undefined,
    disabled: false,
    id: 5,
    label: 'Promoções',
    icon: 'fas fa-ticket-alt',
    link: '/reservas/promocoes',
  },
  {
    reservas: true,
    isvertical: false,
    allowed: undefined,
    disabled: false,
    id: 6,
    label: 'Margem e Desconto',
    icon: 'fas fa-sliders-h',
    hsubitens: [
      {
        id: 7,
        label: 'Disponibilidade X Desconto',
        link: '/reservas/dispondescontos',
        icon: 'fas fa-chart-line me-1',
        parentId: 6,
        allowed: undefined,
        disabled: false
      },
      {
        id: 8,
        label: 'Margem de desconto diário',
        link: '/reservas/margemdescontodiario',
        icon: 'fas fa-chart-line me-1',
        parentId: 6,
        allowed: undefined,
        disabled: false
      },
      {
        id: 9,
        label: 'Margem de ocupação diário',
        link: '/reservas/margemocupacaodiaria',
        icon: 'fas fa-chart-line me-1',
        parentId: 6,
        allowed: undefined,
        disabled: false
      },
      {
        id: 10,
        label: 'Margem de desconto padrão',
        link: '/reservas/margemdescontopadrao',
        icon: 'fas fa-chart-line me-1',
        parentId: 6,
        allowed: undefined,
        disabled: false
      },
      {
        id: 11,
        label: 'Margem de ocupação padrão',
        link: '/reservas/margemocupacaopadrao',
        icon: 'fas fa-chart-line me-1',
        parentId: 6,
        allowed: undefined,
        disabled: false
      },
    ]
  },
  {
    reservas: true,
    isvertical: false,
    allowed: undefined,
    disabled: false,
    id: 12,
    label: 'Datas indisponíveis',
    icon: 'fas fa-calendar',
    link: '/reservas/datasindisponiveis',
  },
  {
    reservas: true,
    isvertical: false,
    allowed: undefined,
    disabled: false,
    id: 13,
    label: 'Mínimo de Diárias',
    icon: 'fas fa-calendar-alt',
    link: '/reservas/minimo-diarias',
  },
]