import { EventEmitter, Injectable } from "@angular/core";
import { MenuItemV1Model } from "../models/menu-v1.model";
import { NavUtilsV1 } from "../services/nav-utils-v1";
import { MenuReservas } from "../menus/reservas.menus";
import { RulesReservasV1Model } from "src/app/pages/colaboradores/permissoes/model/rules-reservas-v1.model";

@Injectable({
  providedIn: "root",
})
export class NavItensReservasEvent {
  /**
   * Eventemitter publico
   */
  public eventMenuItensReservas = new EventEmitter<MenuItemV1Model[]>();

  /**
   * função pública de publicação dos itens de menu reservas
   */
  public async menuReservasSolicitado(_rulesReservas: RulesReservasV1Model) {
    let menuItensReserva: MenuItemV1Model[] = [];

    menuItensReserva = MenuReservas;
    menuItensReserva?.forEach((element: MenuItemV1Model) => {
      if (element.reservas) {
        /** pode acessar item de menu: "Cupons" */
        element.id == 2 // Cupons
          ? ((element.allowed = _rulesReservas.isMenuCupom),
            _rulesReservas.isMenuCupom
              ? element.hsubitens?.length != 0
                ? element.hsubitens?.forEach((subitens) => {
                    subitens.id == 3 // Cupom individual
                      ? (subitens.allowed =
                          _rulesReservas.menuCupom.isSubMenuCupomIndividual)
                      : null;
                    subitens.id == 4 // Cupom promocional
                      ? (subitens.allowed =
                          _rulesReservas.menuCupom.isSubMenuCupomPromocional)
                      : null;
                    subitens.id == 14 // Cupom temporada
                      ? (subitens.allowed =
                          _rulesReservas.menuCupom.isSubMenuCupomTemporada)
                      : null;
                  })
                : null
              : null)
          : null;
        /** pode acessar item de menu: "Promoções" */
        element.id == 5 // Promoções
          ? (element.allowed = _rulesReservas.isMenuPromocoes)
          : null;
        /** pode acessar item de menu: "Margem e desconto" */
        element.id == 6 // Margem e Desconto
          ? ((element.allowed = _rulesReservas.isMenuMargemDesconto),
            _rulesReservas.isMenuMargemDesconto
              ? element.hsubitens?.length != 0
                ? element.hsubitens?.forEach((subitens) => {
                    subitens.id == 7 // Disponibilidade x Desconto
                      ? (subitens.allowed =
                          _rulesReservas.menuMargemDesconto.isSubMenuDisponibilidadeDesconto)
                      : null;
                    subitens.id == 8 // Margem de desconto diário
                      ? (subitens.allowed =
                          _rulesReservas.menuMargemDesconto.isSubMenuMargemDescontoDiario)
                      : null;
                    subitens.id == 9 // Margem de ocupação diário
                      ? (subitens.allowed =
                          _rulesReservas.menuMargemDesconto.isSubMenuMargemOcupacaoDiario)
                      : null;
                    subitens.id == 10 // Margem de desconto padrão
                      ? (subitens.allowed =
                          _rulesReservas.menuMargemDesconto.isSubMenuMargemDescontoPadrao)
                      : null;
                    subitens.id == 11 // Margem de ocupação padrão
                      ? (subitens.allowed =
                          _rulesReservas.menuMargemDesconto.isSubMenuMargemOcupacaoPadrao)
                      : null;
                  })
                : null
              : null)
          : null;
        /** pode acessar iten de menu: "Datas indisponiveis" */
        element.id == 12 // Datas indisponiveis
          ? (element.allowed = _rulesReservas.isMenuDatasIndisponiveis)
          : null;
        /** pode acessar iten de menu: "Minimo de Diárias" */
        element.id == 13 // Minímo Diárias
          ? (element.allowed = _rulesReservas.isMenuMinimoDiarias)
          : null;
      }
    });

    NavUtilsV1.navItensMoment("navitensReservas");

    this.eventMenuItensReservas.emit(menuItensReserva);
  }
}
