import { MenuItemV1Model } from "../models/menu-v1.model";

export const MenuDashBoard: MenuItemV1Model[] = [
  {
    dashboard: true,
    isvertical: false,
    allowed: true,
    disabled: undefined,
    id: 1,   /** será montdo a númeração em tempo real de carregamento do banco de dados */
    label: 'Mapa Diário',
    icon: 'bx bxs-dashboard',
    link: '/dashboard',
  }
]
