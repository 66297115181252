<div id="layout-wrapper">
  <!-- Top bar -->
  <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"
              (settingsButtonClicked)="onSettingsButtonClicked()">
  </app-topbar>
  <app-horizontaltopbar></app-horizontaltopbar>
  <div class="main-content" id="mainContent">
    <div class="page-content">
      <div class="container-fluid">
        <!-- content -->
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- footer -->
    <app-footer></app-footer>
  </div>
</div>
<app-rightsidebar></app-rightsidebar>
<app-menu-lateral-responsivo></app-menu-lateral-responsivo>