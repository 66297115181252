import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Page404Component } from './shared/page404/page404.component';
import { LayoutComponent } from './layouts/layout.component';
import { TrashComponent } from './trash/trash.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },

  /** AUTHENTICAÇÃO */
  {
    path: 'auth', loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule)
  },

  /** módulo AGÊNCIAS */
  {
    path: 'agencia',
    component: LayoutComponent,
    loadChildren: () => import('./pages/agencia/agencia.module')
      .then(m => m.AgenciaModule),
  },

  /** módulo CALEGARIO */
  {
    path: 'calegario',
    component: LayoutComponent,
    loadChildren: () => import('./pages/calegario/calegario.module')
      .then(m => m.CalegarioModule),
  },

  /** módulo COLABORADOREs */
  {
    path: 'colaboradores', //
    component: LayoutComponent,
    loadChildren: () => import('./pages/colaboradores/colaborador.module')
      .then(m => m.ColaboradorModule),
  },

  /** módulo CONFIGURACOES */
  // {
  //   path: 'config',
  //   component: LayoutComponent,
  //   loadChildren: () => import('./pages/configuracoes/configuracoes.module')
  //     .then(m => m.ConfiguracoesModule),
  // },

  /** módulo DASHBOARD */
  {
    path: 'dashboard',
    component: LayoutComponent,
    loadChildren: () => import('./pages/dashboard/dashboard.module')
      .then(m => m.DashboardModule),
  },

  /** módulo GATEWAY */
  {
    path: 'gateway',
    component: LayoutComponent,
    loadChildren: () => import('./pages/gateway/gateway.module')
      .then(m => m.GatewayModule),
  },

  /** RELATORIOS */
  {
    path: 'relatorios',
    // component: LayoutComponent,
    loadChildren: () => import('./pages/relatorios/relatorios.module')
      .then(m => m.RelatoriosModule),
  },

  /** módulo HOSPEDES */
  // {
  //   path: 'hospedes',
  //   component: LayoutComponent,
  //   loadChildren: () => import('./pages/hospedes/hospedes.module')
  //     .then(m => m.HospedesModule),
  // },
  // /** módulo IMPLEMENT */
  // {
  //   path: 'implement',
  //   component: LayoutComponent,
  //   loadChildren: () => import('./pages/implement/implement.module')
  //     .then(m => m.ImplementModule),
  // },
  // /** módulo PARCEIROS */
  // {
  //   path: 'parceiros',
  //   component: LayoutComponent,
  //   loadChildren: () => import('./pages/parceiro/parceiro.module')
  //     .then(m => m.ParceiroModule),
  // },

  // /** módulo PORTAL */
  // {
  //   path: 'portal',
  //   component: LayoutComponent,
  //   loadChildren: () => import('./pages/portal/portal.module')
  //     .then(m => m.PortalModule),
  // },

  // /** módulo RESERVAS */
  {
    path: 'reservas', //
    component: LayoutComponent,
    loadChildren: () => import('./pages/reservas/reservas.module')
      .then(m => m.ReservasModule),
  },

  {
    path: 'trash',
    component: TrashComponent
  },

  /** shared components */
  { path: '**', component: Page404Component }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        scrollPositionRestoration: 'top'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
