<div class="right-bar">
  <ngx-simplebar class="h-100">
      <div class="rightbar-title d-flex align-items-center bg-dark p-3">
          <h5 class="m-0 me-2 text-white">Módulos</h5>
          <a href="javascript:void(0);" class="right-bar-toggle ms-auto" (click)="hide();">
              <i class="mdi mdi-close noti-icon"></i>
          </a>
      </div>
      <div class="row mb-3 m-3 me-0 justify-content-between">
          <div class="col-6">
            <div class="row mouse bg-acesso">
              <div class="col-12" [routerLink]="['/colaboradores']">
                <div class="text-center mt-2">
                  <i class="mdi icon-size mdi-36px mdi-account-group-outline"></i>
                  <br /><span>Colaboradores</span>
                </div>
              </div>
            </div>
          </div>
    
          <div class="col-6">
            <div class="row mouse bg-acesso gray-800">
              <div class="col-12" [routerLink]="['/reservas']">
                <div class="text-center mt-2">
                  <i class="mdi icon-size mdi-36px mdi-account-convert"></i>
                  <br /><span>Reservas</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <!-- <div class="row mb-3 m-3 me-0 justify-content-between">
          <div class="col-6">
            <div class="row mouse bg-acesso">
              <div class="col-12" [routerLink]="[ '/agencia' ]">
                <div class="text-center mt-2">
                  <i class="mdi icon-size mdi-36px mdi-account-tie"></i>
                  <br /><span>Agências</span>
                </div>
              </div>
            </div>
          </div>
    
          <div class="col-6">
            <div class="row mouse bg-acesso">
              <div class="col-12" [routerLink]="[ '/parceiros' ]">
                <div class="text-center mt-2">
                  <i class="mdi icon-size mdi-36px mdi-handshake"></i>
                  <br /><span>Parceiros</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
    
        <!-- <div class="row mb-3 m-3 me-0 justify-content-between">
          <div class="col-6">
            <div class="row mouse bg-acesso">
              <div class="col-12" [routerLink]="[ '/portal' ]">
                <div class="text-center mt-2">
                  <i class="mdi icon-size mdi-36px mdi-monitor-star"></i>
                  <br /><span>Portal</span>
                </div>
              </div>
            </div>
          </div>
    
          <div class="col-6">
            <div class="row mouse bg-acesso">
              <div class="col-12" [routerLink]="[ '/calegario' ]">
                <div class="text-center mt-2">
                  <i class="mdi icon-size mdi-36px mdi-map-marker-radius"></i>
                  <br /><span>Callegario</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
    
        <div class="row mb-3 m-3 me-0 justify-content-between">
          <div class="col-6">
            <div class="row mouse bg-acesso">
              <div class="col-12" [routerLink]="[ '/gateway' ]">
                <div class="text-center mt-2">
                  <i class="mdi icon-size mdi-36px mdi-cash-check"></i>
                  <br /><span>Gateway</span>
                </div>
              </div>
            </div>
          </div>
    
          <!-- <div class="col-6">
            <div class="row mouse bg-acesso">
              <div class="col-12" [routerLink]="[ '/hospedes' ]">
                <div class="text-center mt-2">
                  <i class="mdi icon-size mdi-36px mdi-account-supervisor-circle"></i>
                  <br /><span>Hospedes</span>
                </div>
              </div>
            </div>
          </div> -->
        </div>
    
        <!-- <div class="row mb-3 m-3 me-0 justify-content-between">
          <div class="col-6">
            <div class="row mouse bg-acesso">
              <div class="col-12" [routerLink]="[ '/config' ]">
                <div class="text-center mt-2">
                  <i class="mdi icon-size mdi-36px mdi-monitor-dashboard"></i>
                  <br /><span>Configurações</span>
                </div>
              </div>
            </div>
          </div>
    
          <div class="col-6">
            <div class="row mouse bg-acesso">
              <div class="col-12" [routerLink]="[ '/implement' ]">
                <div class="text-center mt-2">
                  <i class="mdi icon-size mdi-36px mdi-cog-sync"></i>
                  <br /><span>Implement</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
  </ngx-simplebar>
</div>