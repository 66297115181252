import { DOCUMENT } from '@angular/common';
import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { MenuItemV1Model } from '../navegation/models/menu-v1.model';
import { NavItensColaboradoresEvent } from '../navegation/events/nav-itens-colaboradores.event';
import { NavItensConfiguracoesEvent } from '../navegation/events/nav-itens-configuracoes.event';
import { NavItensDashBoardEvent } from '../navegation/events/nav-itens-dashboard.event';
import { NavItensGatewayEvent } from '../navegation/events/nav-itens-gateway.event';
import { NavItensHospedesEvent } from '../navegation/events/nav-itens-manager-hospedes.event';
import { NavItensMomentEvent } from '../navegation/events/nav-itens-moment.event';
import { NavItensReservasEvent } from '../navegation/events/nav-itens-reservas.event';
import { MenuHospedes } from '../navegation/menus/manager-hospedes.menus';
import { HSubItensModel } from '../navegation/models/h-subitens.model';

//Navegation Components

@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss'],
})

/**
 * Horizontal-Topbar Component
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {
  menuItems: MenuItemV1Model[] = [];

  constructor(
    private readonly navItensHospedesEvent: NavItensHospedesEvent,
    private readonly navitenscolaboreadores: NavItensColaboradoresEvent,
    private readonly navitensReservas: NavItensReservasEvent,
    private readonly navItensMoment: NavItensMomentEvent,
    private readonly navItensDashBoardEvent: NavItensDashBoardEvent,
    private readonly navItensConfiguracoesEvent: NavItensConfiguracoesEvent,
    private readonly navItensGatewayEvent: NavItensGatewayEvent,
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public _cookiesService: CookieService
  ) {
    /** modulos acessando no momento */
    this.navItensMoment.eventMenuItensMoment
      .subscribe(x => {
        this.menuItems = [];
        this.menuItems = x;
      });
    /** Modulo Colaboradores */
    this.navitenscolaboreadores.eventMenuItensColaboradores
      .subscribe(x => {
        this.menuItems = [];
        this.menuItems = x;
      });
    /** Modulo Hospedes */
    this.navItensHospedesEvent.eventMenuItensHospedes
      .subscribe(x => {
        this.menuItems = [];
        this.menuItems = x;
      });
    /** Modulo Reservas */
    this.navitensReservas.eventMenuItensReservas
      .subscribe(x => {
        this.menuItems = [];
        this.menuItems = x;
      });
    /** Modulo DashBoard */
    this.navItensDashBoardEvent.eventMenuItensDashBoard
      .subscribe(x => {
        this.menuItems = [];
        this.menuItems = x;
      });
    /** Modulo Configurações */
    this.navItensConfiguracoesEvent.eventMenuItensConfiguracoes
      .subscribe(x => {
        this.menuItems = [];
        this.menuItems = x;
      });
    /** Modulo Gateway */
    this.navItensGatewayEvent.eventMenuItensGateway
      .subscribe(x => {
        this.menuItems = [];
        this.menuItems = x;
      });
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });
  }

  ngOnInit(): void {
    this.initialize();
  }

  /**
   * Initialize
   */
  initialize(): void {
    let menuItemstmp: MenuItemV1Model[] = MenuHospedes;
    menuItemstmp?.forEach(
      (element: MenuItemV1Model) => {
        element.colaborador ? this.menuItems.push(element) : null;
      }
    );
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItemV1Model) {
    return item.hsubitens !== undefined ? item.hsubitens.length > 0 : false;
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * On menu click
   */
  onMenuClick(event: any) {
    const nextEl = event.target.nextElementSibling;
    if (nextEl) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove('show');
      }
      nextEl.classList.toggle('show');
    }
    return false;
  }

  ngAfterViewInit() {
    this.activateMenu();
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className: any) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activates the menu
   */
  private activateMenu() {
    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
                const menuelement = document.getElementById(
                  'topnav-menu-content'
                );
                if (menuelement !== null)
                  if (menuelement.classList.contains('show'))
                    document
                      .getElementById('topnav-menu-content')!
                      .classList.remove('show');
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links: any = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('active');
              }
            }
          }
        }
      }
    }
  }
}
