import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-lateral-responsivo',
  templateUrl: './menu-lateral-responsivo.component.html',
  styleUrls: ['./menu-lateral-responsivo.component.scss']
})
export class MenuLateralResponsivoComponent {

  /**
   * Hide the sidebar
   */
  public hide() {
    document.body.classList.remove('right-bar-enabled');
  }
}
