<section class="container card">
  <div class="card-content">
    <div class="card-body">
      <h6 class="card-title mb-3">Gerar Liquidez Mensal</h6>
      <form class="form" name="form-liquidez" (ngSubmit)="onSubmitLiquidezMensal()">
        <div class="form-floating">
          <input type="text" id="competencia" name="competencia" class="form-control"
                 [(ngModel)]="solicitarLiquidez.competencia" placeholder="Informe a Competência">
          <label for="competencia" class="form-label">Informe a Competência:</label>
        </div>
        <div class="form-floating my-3">
          <input type="text" id="codEmpresa" name="codEmpresa" class="form-control"
                 [(ngModel)]="solicitarLiquidez.codEmpresa" placeholder="Infome o Código da Empresa">
          <label for="codEmpresa" class="form-label">Informe o Código da Empresa:</label>
        </div>
        <div class="form-floating my-3">
          <input type="text" id="userDS" name="userDS" class="form-control" [(ngModel)]="solicitarLiquidez.userDS"
                 placeholder="Informe o usuário da Domínio Sistemas">
          <label for="userDS" class="form-label">Informe o usuário da Domínio Sistemas:</label>
        </div>
        <div class="form-floating">
          <input type="text" id="password" name="password" class="form-control" [(ngModel)]="solicitarLiquidez.password"
                 placeholder="Informe a senha">
          <label for="password" class="form-label">Informe a senha da Domínio Sistemas:</label>
        </div>
        <div class="my-3">
          <label class="fw-bold d-block">Fechar Domínio Sistemas após finalizar o Processo?</label>
          <div class="form-check form-check-inline">
            <input type="radio" id="isFechaDSTrue" name="isFechaDS" class="form-check-input" 
              [(ngModel)]="solicitarLiquidez.isFechaDS" [value]="true">
            <label for="isFechaDSTrue" class="form-label">Sim</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" id="isFechaDSFalse" name="isFechaDS" class="form-check-input" 
              [(ngModel)]="solicitarLiquidez.isFechaDS" [value]="false">
            <label for="isFechaDSFalse" class="form-label">Não</label>
          </div>
        </div>
        <button class="btn btn-success my-3" type="submit">Solicitar Liquidez Mensal</button>
      </form>
    </div>

    <div class="card-body">
      <h6 class="card-title mb-3">Gerar Liquidez IRRF</h6>
      <form class="form" name="form-liquidez" (ngSubmit)="onSubmitLiquidezIRRF()">
        <div class="form-floating">
          <input type="text" id="competencia" name="competencia" class="form-control"
                 [(ngModel)]="solicitarLiquidezIRRF.competencia" placeholder="Informe a Competência">
          <label for="competencia" class="form-label">Informe a Competência:</label>
        </div>
        <div class="form-floating my-3">
          <input type="text" id="codEmpresa" name="codEmpresa" class="form-control"
                 [(ngModel)]="solicitarLiquidezIRRF.codEmpresa" placeholder="Infome o Código da Empresa">
          <label for="codEmpresa" class="form-label">Informe o Código da Empresa:</label>
        </div>
        <div class="form-floating my-3">
          <input type="number" id="ultDiaMesCompetencia" name="ultDiaMesCompetencia" class="form-control" [(ngModel)]="solicitarLiquidezIRRF.ultDiaMesCompetencia"
                 placeholder="Informe o ultimo dia do mês da competência">
          <label for="ultDiaMesCompetencia" class="form-label">Informe o ultimo dia do mês da competência:</label>
        </div>
        <div class="form-floating my-3">
          <input type="text" id="userDS" name="userDS" class="form-control" [(ngModel)]="solicitarLiquidezIRRF.userDS"
                 placeholder="Informe o usuário da Domínio Sistemas">
          <label for="userDS" class="form-label">Informe o usuário da Domínio Sistemas:</label>
        </div>
        <div class="form-floating">
          <input type="text" id="password" name="password" class="form-control" [(ngModel)]="solicitarLiquidezIRRF.password"
                 placeholder="Informe a senha">
          <label for="password" class="form-label">Informe a senha da Domínio Sistemas:</label>
        </div>
        <div class="my-3">
          <label class="fw-bold d-block">Fechar Domínio Sistemas após finalizar o Processo?</label>
          <div class="form-check form-check-inline">
            <input type="radio" id="isFechaDSIRRFTrue" name="isFechaDS" class="form-check-input" 
              [(ngModel)]="solicitarLiquidezIRRF.isFechaDS" [value]="true">
            <label for="isFechaDSIRRFTrue" class="form-label">Sim</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" id="isFechaDSIRRFFalse" name="isFechaDS" class="form-check-input" 
              [(ngModel)]="solicitarLiquidezIRRF.isFechaDS" [value]="false">
            <label for="isFechaDSIRRFFalse" class="form-label">Não</label>
          </div>
        </div>
        <button class="btn btn-success my-3" type="submit">Solicitar Liquidez IRRF</button>
      </form>
    </div>

    <!-- <div class="card-body">
      <h6 class="card-title mb-3">
        Acionar Fluxo Não Assistido
      </h6>
      <form class="form" name="form-nao-assistido" (ngSubmit)="onSubmitNaoAssistido()">
        <div class="form-floating mb-3">
          <input type="text" name="msg" id="msg" class="form-control" placeholder="Mensagem a enviar" [(ngModel)]="mensagemDash.mensagemDash">
          <label for="msg" class="form-label">Mensagem a ser enviada</label>
        </div>
        <button class="btn btn-primary" type="submit">Acionar Fluxo</button>
      </form>
    </div> -->

    <div class="card-body">
      <h6 class="card-title mb-3">Gerar Arquivo Contabilidade (Mensal)</h6>
      <form class="form" name="form-liquidez" (ngSubmit)="onSubmitArquivoMensalContabil()">
        <div class="form-floating">
          <input type="text" id="competencia" name="competencia" class="form-control"
                 [(ngModel)]="solicitarArquivoContabilidadeMensal.competencia" placeholder="Informe a Competência">
          <label for="competencia" class="form-label">Informe a Competência:</label>
        </div>
        <div class="form-floating my-3">
          <input type="text" id="codEmpresa" name="codEmpresa" class="form-control"
                 [(ngModel)]="solicitarArquivoContabilidadeMensal.empresa" placeholder="Infome o Código da Empresa">
          <label for="codEmpresa" class="form-label">Informe o Código da Empresa:</label>
        </div>
        <div class="form-floating my-3">
          <input type="text" id="userDS" name="userDS" class="form-control" [(ngModel)]="solicitarArquivoContabilidadeMensal.userDS"
                 placeholder="Informe o usuário da Domínio Sistemas">
          <label for="userDS" class="form-label">Informe o usuário da Domínio Sistemas:</label>
        </div>
        <div class="form-floating">
          <input type="text" id="password" name="password" class="form-control" [(ngModel)]="solicitarArquivoContabilidadeMensal.passDS"
                 placeholder="Informe a senha">
          <label for="password" class="form-label">Informe a senha da Domínio Sistemas:</label>
        </div>
        <div class="my-3">
          <label class="fw-bold d-block">Fechar Domínio Sistemas após finalizar o Processo?</label>
          <div class="form-check form-check-inline">
            <input type="radio" id="isFechaDSTrue" name="isFechaDS" class="form-check-input" 
              [(ngModel)]="solicitarArquivoContabilidadeMensal.isFechaDS" [value]="true">
            <label for="isFechaDSTrue" class="form-label">Sim</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" id="isFechaDSFalse" name="isFechaDS" class="form-check-input" 
              [(ngModel)]="solicitarArquivoContabilidadeMensal.isFechaDS" [value]="false">
            <label for="isFechaDSFalse" class="form-label">Não</label>
          </div>
        </div>
        <button class="btn btn-success mb-3" type="submit">Solicitar Arquivo</button>
      </form>
    </div>
  </div>
</section>

<section class="container">
  
</section>