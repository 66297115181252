import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityUtil } from 'src/app/core/utils/security.util';
import { HeaderUserService } from 'src/app/core/services/headeruser.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { UserAuthV1Model } from 'src/app/auth/models/user-auth-v1.model';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  userLogado!: UserAuthV1Model | null;
  accessItensDashBoard?: any;
  constructor(
    private route: Router,
    private loaderService: LoaderService,
    private headerService: HeaderUserService,
  ) {
    /**
     * Coleta dados do usuário authenticado
     */
    this.userLogado = SecurityUtil.getAccount();
    this.accessItensDashBoard = this.userLogado?._rulesAccount._rulesColaborador;
    /**
     * atualiza os dados da conta quando o perfil e atualizado
     */
    this.headerService.eventoAlteraDados
    .subscribe(x => {
      this.getUseLogado();
    });
  }

  getUseLogado(){
    this.userLogado = SecurityUtil.getAccount();
  }

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() menuResponsivoButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit(): void {
    /**
     * Paraliza o processo de loader
     */
    this.loaderService.stopLoader();
  }

  openDropDownMenu(dropdownMenu:NgbDropdown){
    dropdownMenu.open();
  } 
  closeDropDownMenu(dropdownMenu:NgbDropdown){
    dropdownMenu.close();
  } 

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Logout the user
   */
   logout() {
    SecurityUtil.clear();
    this.route.navigate([`/auth/login`])
  }

}
