// Components do sistema
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
// Services
import { loginV1Service } from '../../auth/services/login-v1.service';
// Utils
import { SecurityUtil } from '../utils/security.util';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private route: Router,
    private loginService: loginV1Service
  ) { }

  // verificar quanto tempo tem desde o ultimo refreshtoken
  refreshtoken() {
    this.loginService.refreshtoken()
      .subscribe({
        next: (resultado: any) => {
          SecurityUtil.setToken(resultado.data.token);
          this.updateNotificacoes();
        }, 
        error: (err) => {
          // SecurityUtil.clear();
          this.route.navigate(['/auth/login']);
        }
      });
  }

  userLogado() {

  }

  updateNotificacoes() {

  }

}
