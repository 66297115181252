import { MenuItemV1Model } from "../models/menu-v1.model";

export const MenuConfiguracoes: MenuItemV1Model[] = [
  {
    config: true,
    isvertical: false,
    allowed: true,
    disabled: undefined,
    id: 1,   /** será montdo a númeração em tempo real de carregamento do banco de dados */
    label: 'DashBoard',
    icon: 'fas fa-home',
    link: '/config',
  },
  {
    config: true,
    isvertical: false,
    allowed: true,
    disabled: undefined,
    id: 2,
    label: 'Módulos',
    icon: 'fas fa-id-card-alt',
    hsubitens: [
      {
        id: 3,
        parentId: 2,
        label: 'Módulos ativos',
        link: '/config/modulos/ativos',
        icon: 'fas fa-user-edit me-1',
        allowed: true,
        disabled: undefined
      },
      {
        id: 4,
        parentId: 2,
        label: 'Módulos disponíveis',
        link: '/config/modulos/disponiveis',
        icon: 'fas fa-user-circle me-2',
        allowed: true,
        disabled: undefined
      }
    ]
  },
  {
    config: true,
    isvertical: false,
    allowed: true,
    disabled: undefined,
    id: 5,
    label: 'Layout',
    icon: 'fas fa-id-card-alt',
    hsubitens: [
      {
        id: 6,
        parentId: 5,
        label: 'Layout de acesso',
        link: '/config/layout/acesso',
        icon: 'fas fa-user-edit me-1',
        allowed: true,
        disabled: undefined
      },
      {
        id: 7,
        parentId: 5,
        label: 'Layout dashboard',
        link: '/config/layout/dashboard',
        icon: 'fas fa-user-edit me-1',
        allowed: true,
        disabled: undefined
      },
      {
        id: 8,
        parentId: 5,
        label: 'Layout e-mails',
        link: '/config/layout/emails',
        icon: 'fas fa-user-edit me-1',
        allowed: true,
        disabled: undefined
      // },
      // {
      //   id: 9,
      //   parentId: 5,
      //   label: 'Layout callegario',
      //   link: '/config/background/promocoes/banners-promocionais',
      //   icon: 'fas fa-user-edit me-1',
      //   allowed: true,
      //   disabled: undefined
      }
    ]
  },
  {
    config: true,
    isvertical: false,
    allowed: true,
    disabled: undefined,
    id: 10,
    label: 'Gateway',
    icon: 'fas fa-id-card-alt',
    hsubitens: [
      {
        id: 11,
        parentId: 10,
        label: 'Cadastro Asaas',
        link: '/config/gateway/cadastroasaas',
        icon: 'fas fa-user-edit me-1',
        allowed: true,
        disabled: undefined
      },
      {
        id: 12,
        parentId: 10,
        label: 'Layout pagamento',
        link: '/config/gateway/layoutpagamentos',
        icon: 'fas fa-user-circle me-2',
        allowed: true,
        disabled: undefined
      }
    ]
  },
  {
    config: true,
    isvertical: false,
    allowed: true,
    disabled: undefined,
    id: 13,
    label: 'Callegario',
    icon: 'fas fa-id-card-alt',
    hsubitens: [
      {
        id: 14,
        parentId: 13,
        label: 'Cadastro Callegario',
        link: '/config/callegario/cadastro',
        icon: 'fas fa-user-edit me-1',
        allowed: true,
        disabled: undefined
      },
      {
        id: 15,
        parentId: 13,
        label: 'Layout Hospedagens',
        link: '/config/callegario/layouthospedagens',
        icon: 'fas fa-user-edit me-1',
        allowed: true,
        disabled: undefined
      },
      {
        id: 16,
        parentId: 13,
        label: 'Layout reservas',
        link: '/config/callegario/layoutreservas',
        icon: 'fas fa-user-edit me-1',
        allowed: true,
        disabled: undefined
      }
    ]
  },
  {
    config: true,
    isvertical: false,
    allowed: true,
    disabled: undefined,
    id: 17,   /** será montdo a númeração em tempo real de carregamento do banco de dados */
    label: 'Financeiro',
    icon: 'fas fa-home',
    link: '/config/financeiro',
  }
]
