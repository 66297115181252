import { Component} from '@angular/core';
import { TrashV1Service } from './service/trash-v1.service';

@Component({
  selector: 'app-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.scss']
})
export class TrashComponent {
  /**
   *
   */
  constructor(
    private trashV1Service: TrashV1Service
  ) {}

  solicitarLiquidez: SolicitarLiquidez = {
    codEmpresa: '',
    competencia: '',
    isFechaDS: false,
    userDS: '',
    password: ''
  }

  solicitarLiquidezIRRF: SolicitarLiquidezIRRF = {
    codEmpresa: '',
    competencia: '',
    isFechaDS: false,
    userDS: '',
    password: '',
    ultDiaMesCompetencia: 0
  }

  solicitarArquivoContabilidadeMensal: SolicitarArquivoMensalContabilidade = {
    competencia: '',
    empresa: '',
    codSIAGRI: '',
    isFechaDS: false,
    passDS: '',
    userDS: ''
  }

  mensagemDash: AcionarNaoAssistido = {mensagemDash: ''};

  onSubmitLiquidezMensal(){
    this.trashV1Service
    .acionaFlow(this.solicitarLiquidez)
    .subscribe(
      () => {
        alert('Solicitação enviada com sucesso.')
      }
    )
  }

  onSubmitLiquidezIRRF(){
    this.trashV1Service
    .acionaFlowIRRF(this.solicitarLiquidezIRRF)
    .subscribe(
      () => {
        alert('Solicitação enviada com sucesso.')
      }
    )
  }

  onSubmitNaoAssistido(){
    this.trashV1Service
    .acionaFlowNaoAssistido(this.mensagemDash!)
    .subscribe(
      () => {
        alert('Fluxo para acionamento não assistido ativado com sucesso!')
      }
    )
  }

  onSubmitArquivoMensalContabil(){
    this.trashV1Service
    .acionaFlowGerarArqMensalContabilidadeAC(this.solicitarArquivoContabilidadeMensal)
    .subscribe(
      () => {
        alert('Solicitação enviada com sucesso!')
      }
    )
  }

}

class SolicitarLiquidez{
  constructor(
    public competencia: string, 
    public codEmpresa: string, 
    public userDS: string, 
    public password: string,
    public isFechaDS: boolean 
    ) {}
}

class SolicitarLiquidezIRRF{
  constructor(
    public competencia: string, 
    public codEmpresa: string, 
    public userDS: string, 
    public password: string,
    public isFechaDS: boolean,
    public ultDiaMesCompetencia: number
    ) {}
}

class AcionarNaoAssistido{
  /**
   *
   */
  constructor(
    public mensagemDash: string
  ) {}
}

class SolicitarArquivoMensalContabilidade{
  constructor(
    public competencia: string,
    public empresa: string,
    public codSIAGRI: string,
    public userDS: string,
    public passDS: string,
    public isFechaDS: boolean 
  ) {}
}
