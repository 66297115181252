import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrashV1Service {

  constructor(
    private http: HttpClient
  ) { }

  acionaFlow(
    solicitarLiquidez: {
      competencia: string, 
      codEmpresa: string, 
      userDS: string, 
      password: string,
      isFechaDS: boolean
    },
  ) {
    return this.http.post(
      this.urlFlow,
      solicitarLiquidez
    )
  }

  acionaFlowIRRF(
    solicitarLiquidezIRRF: {
      competencia: string, 
      codEmpresa: string, 
      userDS: string, 
      password: string,
      isFechaDS: boolean,
      ultDiaMesCompetencia: number
    },
  ){
    return this.http.post(
      this.urlFlowLiquidezIRRF,
      solicitarLiquidezIRRF
    )
  }

  acionaFlowNaoAssistido(
    acionarNaoAssistido : {
      mensagemDash: string
    }
  ) {
    return this.http.post(
      this.urlFlowNaoAssistido,
      acionarNaoAssistido
    )
  }

  acionaFlowGerarArqMensalContabilidadeAC(
    data: {
      competencia: string,
      empresa: string,
      codSIAGRI: string,
      userDS: string,
      passDS: string,
      isFechaDS: boolean 
    }
  ){
    return this.http.post(
      this.urlFlowGerarArqMensalContabilidade,
      data
    )
  }

  urlFlowGerarArqMensalContabilidade: string = 'https://prod-25.brazilsouth.logic.azure.com:443/workflows/bf3afd18a20e4b63b57128513f25ca5c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=NX-VoXODRzR1rntPZymUuthPNtDIyMSMqzBrpBpf3bw';
  urlFlow: string = 'https://prod-06.brazilsouth.logic.azure.com:443/workflows/c3850de5d35d4bfa94f346aa3a471c54/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=7uMwXFDtu9mEUHbbSRER8ahIdiNSp8bjIMJenArOAK4';
  urlFlowNaoAssistido: string = 'https://prod-27.brazilsouth.logic.azure.com:443/workflows/2ce81829378249aaad2f38c97ba07da5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=pyPxK_rHwB8SBBXdiLNdutNiPQRFsD2wZxJPdhVjmCs';
  urlFlowLiquidezIRRF: string = 'https://prod-02.brazilsouth.logic.azure.com:443/workflows/936a939c478d487593417e7d0073d11f/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=IK2Gn9wun2CYqREIm1Op7VSwpAuO_vmyyjuM70DJa5k';
}
