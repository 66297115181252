import { EventEmitter, Injectable } from "@angular/core";
import { MenuDashBoard } from "../menus/dashboaard.menus";
import { MenuItemV1Model } from "../models/menu-v1.model";
import { NavUtilsV1 } from "../services/nav-utils-v1";

@Injectable({
  providedIn: 'root'
})
export class NavItensDashBoardEvent {

  /**
   * Eventemitter publico
   */
  public eventMenuItensDashBoard = new EventEmitter<MenuItemV1Model[]>();

  public async menuDashBoardSolicitado() {

    let menuItensDashBoardTmp: MenuItemV1Model[] = [];
    let menuItensDashBoard: MenuItemV1Model[] = [];

    menuItensDashBoardTmp = MenuDashBoard;
    menuItensDashBoardTmp?.forEach(
      (element: MenuItemV1Model) => {
        element.dashboard ? menuItensDashBoard.push(element) : null;
      }
    );

    NavUtilsV1.navItensMoment('navitensDashBoard');

    this.eventMenuItensDashBoard.emit(menuItensDashBoard);

  }
}
