import { MenuItemV1Model } from "../models/menu-v1.model";

export const MenuHospedes: MenuItemV1Model[] = [
  {
    hospedes: true,
    isvertical: false,
    allowed: undefined,
    disabled: undefined,
    id: 1,   /** será montdo a númeração em tempo real de carregamento do banco de dados */
    label: 'Hospedes',
    icon: 'fas fa-home',
    link: '/mnghospedes',
  },
  {
    hospedes: true,
    isvertical: false,
    allowed: undefined,
    disabled: undefined,
    id: 2,
    label: 'Definições',
    icon: 'fas fa-id-card-alt',
    hsubitens: [
      {
        id: 3,
        label: 'Define desconto',
        link: '/mnghospedes',
        icon: 'fas fa-user-edit me-1',
        parentId: 2,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 4,
        label: 'Define datas indisponíveis',
        link: '/mnghospedes',
        icon: 'fas fa-user-circle me-2',
        parentId: 2,
        allowed: undefined,
        disabled: undefined
      }
    ]
  },
  {
    hospedes: true,
    isvertical: false,
    allowed: undefined,
    disabled: undefined,
    id: 5,
    label: 'Promoções',
    icon: 'fas fa-id-card-alt',
    hsubitens: [
      {
        id: 6,
        label: 'Cupons de descontos',
        link: '/mnghospedes',
        icon: 'fas fa-user-edit me-1',
        parentId: 5,
        allowed: undefined,
        disabled: undefined
      },
      {
        id: 7,
        label: 'Banners promocionais',
        link: '/mnghospedes',
        icon: 'fas fa-user-edit me-1',
        parentId: 5,
        allowed: undefined,
        disabled: undefined
      }
    ]
  }
]
